// Import dependencies from node_modules
import "jquery";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "select2";
import "select2/dist/js/i18n/fr.js";
import Cleave from "cleave.js";

window.Swal = require("sweetalert2");
window.toastr = require("toastr");

$(function () {
    /**
     * FAQ items : Open accordion panel on click
     */
    $(".post-faq").on("click", function () {
        if ($(this).hasClass("open") === false) {
            closeFaqItems();
            openFaqItem($(this));
        }
    });

    /**
     * Nos missions pages, handle tabs.
     */
    $("#missions-menu a").on("click", function (event) {
        event.preventDefault();
        $(this).parent().addClass("current");
        $(this).parent().siblings().removeClass("current");
        var tab = $(this).attr("href");
        $(".missions-tab-content").not(tab).css("display", "none");
        $(tab).fadeIn();
    });

    /*
     * Init tippy script on content with "data-tippy-content" attr
     */
    tippy("[data-tippy-content]");

    /**
     * Init tippy script on content with "data-template" attr
     */
    tippy(".tooltip-info--template", {
        content(reference) {
            const id = reference.getAttribute("data-template");
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true,
    });

    /*
     * Add CSRF Themosis Token in XHR request headers
     */
    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
    });

    /**
     * Handle AJAX sending link for subrogé invitation in Dossier / ComtpeGestion creation.
     */
    $(document).on("click", "#send-subroge-invite", function (e) {
        var postUrl = $(this).attr("data-url");

        Swal.fire({
            title: "Invitation compte subrogé ?",
            text: "Entrer l'adresse e-mail du subrogé pour l’inviter à créer son espace.",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#1e824c",
            confirmButtonText: "Envoyer",
            cancelButtonText: "Fermer",
            focusConfirm: false,
            input: "email",
            inputAttributes: {
                autocapitalize: "off",
            },
            validationMessage: "Adresse e-mail invalide",
            showLoaderOnConfirm: true,
            preConfirm: (email) => {
                let formData = new FormData();
                formData.append("email", email);

                return fetch(postUrl, {
                    method: "post",
                    body: formData,
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        ),
                    },
                })
                    .then((response) => response.json()) // Transform the data into json
                    .then(function (data) {
                        if (data.status === "failed") {
                            throw new Error(data.message);
                        }
                        return data;
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(
                            `Une erreur est survenue : ${error.message}`
                        );
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: "Succès",
                    icon: "success",
                    text: `L'invitation à bien été envoyé à l'adresse email ${result.value.message}`,
                });
            }
        });
    });

    /**
     * Smooth scroll to anchor links
     */
    $(".smooth-link").on("click", function (event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, "") ==
                this.pathname.replace(/^\//, "") &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length
                ? target
                : $("[name=" + this.hash.slice(1) + "]");
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $("html, body").animate(
                    {
                        scrollTop: target.offset().top,
                    },
                    1000,
                    function () {
                        // Callback after animation
                        var $target = $(target);
                        $target.trigger("focus");
                        if ($target.is(":focus")) {
                            // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                            $target.trigger("focus");
                        }
                    }
                );
            }
        }
    });

    /* Delete CG message */

    // TODO: // OPTIMIZE:
    let confirmed = false;

    $(".delete-confirm").on("click", function (e) {
        e.preventDefault();
        const form = $(this).parents("form");
        if (!confirmed) {
            e.preventDefault();
            Swal.fire({
                title: "Supprimer le compte de gestion",
                text: "Êtes vous sur de vouloir supprimer ce compte de gestion ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Je supprime",
                cancelButtonText: "Non",
                reverseButtons: true,
            }).then(function (result) {
                if (result.value) {
                    confirmed = true;
                    form.trigger("submit");
                }
            });
        }
    });

    /**
     * Add SWAL confirmation before cancel document
     */
    $(".cancel-confirm").on("click", function (e) {
        e.preventDefault();
        const form = $(this).parents("form");
        if (!confirmed) {
            e.preventDefault();
            Swal.fire({
                title: "Annuler le compte de gestion",
                text: "Êtes vous sur de vouloir annuler ce compte de gestion ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Je l'annule",
                cancelButtonText: "Non",
                reverseButtons: true,
            }).then(function (result) {
                if (result.value) {
                    confirmed = true;
                    form.trigger("submit");
                }
            });
        }
    });

    /**
     * Init select2 script on content with "select2js" class
     */
    $(".select2js").select2({
        language: "fr",
    });
}); // end JQuery DOM loading function

/* Input date mask mask with Cleave.js */

const inputsDateCollection = document.getElementsByClassName("datepicker"),
    inputsDate = Array.from(inputsDateCollection),
    dateOptions = {
        date: true,
        datePattern: ["d", "m", "Y"],
    };

inputsDate.forEach(function (date) {
    if (date.classList.contains("table-filter__date")) {
        dateOptions.delimiter = "-";
    }

    new Cleave(date, dateOptions);
});

const inputsYearCollection = document.getElementsByClassName("yearpicker");
const inputsYear = Array.from(inputsYearCollection);

inputsYear.forEach(function (year) {
    new Cleave(year, {
        date: true,
        datePattern: ["Y"],
    });
});

/**
 * FAQ items : closePanel function, close post_faq div
 */
function closeFaqItems() {
    $(".post-faq").each(function () {
        $(this).find(".post-faq__content").slideUp();
        $(this).find(".post-faq__plus").text("+");
        $(this).removeClass("open");
    });
}

/**
 * FAQ items : closePanel function, open post_faq div
 */
function openFaqItem($el) {
    $el.find(".post-faq__content").slideToggle();
    $el.find(".post-faq__plus").text("-");
    $el.addClass("open");
}
